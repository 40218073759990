<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Ion Icons component
 */
export default {
   page: {
    title: "Ion Icons",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Ion Icons",
      items: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Icons",
          href: "/"
        },
        {
          text: "Ion Icons",
          active: true
        }
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Material style</h4>

            <div class="row icon-demo-content">
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-add"></i> ion ion-md-add
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-add-circle"></i> ion ion-md-add-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-add-circle-outline"></i> ion ion-md-add-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-airplane"></i> ion ion-md-airplane
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-alarm"></i> ion ion-md-alarm
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-albums"></i> ion ion-md-albums
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-alert"></i> ion ion-md-alert
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-american-football"></i> ion ion-md-american-football
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-analytics"></i> ion ion-md-analytics
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-aperture"></i> ion ion-md-aperture
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-apps"></i> ion ion-md-apps
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-appstore"></i> ion ion-md-appstore
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-archive"></i> ion ion-md-archive
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-back"></i> ion ion-md-arrow-back
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-down"></i> ion ion-md-arrow-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-dropdown"></i> ion ion-md-arrow-dropdown
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-dropdown-circle"></i> ion ion-md-arrow-dropdown-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-dropleft"></i> ion ion-md-arrow-dropleft
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-dropleft-circle"></i> ion ion-md-arrow-dropleft-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-dropright"></i> ion ion-md-arrow-dropright
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-dropright-circle"></i> ion ion-md-arrow-dropright-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-dropup"></i> ion ion-md-arrow-dropup
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-dropup-circle"></i> ion ion-md-arrow-dropup-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-forward"></i> ion ion-md-arrow-forward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-round-back"></i> ion ion-md-arrow-round-back
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-round-down"></i> ion ion-md-arrow-round-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-round-forward"></i> ion ion-md-arrow-round-forward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-round-up"></i> ion ion-md-arrow-round-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-arrow-up"></i> ion ion-md-arrow-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-at"></i> ion ion-md-at
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-attach"></i> ion ion-md-attach
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-backspace"></i> ion ion-md-backspace
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-barcode"></i> ion ion-md-barcode
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-baseball"></i> ion ion-md-baseball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-basket"></i> ion ion-md-basket
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-basketball"></i> ion ion-md-basketball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-battery-charging"></i> ion ion-md-battery-charging
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-battery-dead"></i> ion ion-md-battery-dead
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-battery-full"></i> ion ion-md-battery-full
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-beaker"></i> ion ion-md-beaker
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-bed"></i> ion ion-md-bed
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-beer"></i> ion ion-md-beer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-bicycle"></i> ion ion-md-bicycle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-bluetooth"></i> ion ion-md-bluetooth
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-boat"></i> ion ion-md-boat
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-body"></i> ion ion-md-body
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-bonfire"></i> ion ion-md-bonfire
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-book"></i> ion ion-md-book
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-bookmark"></i> ion ion-md-bookmark
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-bookmarks"></i> ion ion-md-bookmarks
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-bowtie"></i> ion ion-md-bowtie
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-briefcase"></i> ion ion-md-briefcase
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-browsers"></i> ion ion-md-browsers
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-brush"></i> ion ion-md-brush
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-bug"></i> ion ion-md-bug
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-build"></i> ion ion-md-build
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-bulb"></i> ion ion-md-bulb
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-bus"></i> ion ion-md-bus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-business"></i> ion ion-md-business
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cafe"></i> ion ion-md-cafe
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-calculator"></i> ion ion-md-calculator
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-calendar"></i> ion ion-md-calendar
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-call"></i> ion ion-md-call
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-camera"></i> ion ion-md-camera
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-car"></i> ion ion-md-car
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-card"></i> ion ion-md-card
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cart"></i> ion ion-md-cart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cash"></i> ion ion-md-cash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cellular"></i> ion ion-md-cellular
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-chatboxes"></i> ion ion-md-chatboxes
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-chatbubbles"></i> ion ion-md-chatbubbles
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-checkbox"></i> ion ion-md-checkbox
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-checkbox-outline"></i> ion ion-md-checkbox-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-checkmark"></i> ion ion-md-checkmark
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-checkmark-circle"></i> ion ion-md-checkmark-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-checkmark-circle-outline"></i> ion ion-md-checkmark-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-clipboard"></i> ion ion-md-clipboard
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-clock"></i> ion ion-md-clock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-close"></i> ion ion-md-close
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-close-circle"></i> ion ion-md-close-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-close-circle-outline"></i> ion ion-md-close-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cloud"></i> ion ion-md-cloud
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cloud-circle"></i> ion ion-md-cloud-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cloud-done"></i> ion ion-md-cloud-done
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cloud-download"></i> ion ion-md-cloud-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cloud-outline"></i> ion ion-md-cloud-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cloud-upload"></i> ion ion-md-cloud-upload
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cloudy"></i> ion ion-md-cloudy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cloudy-night"></i> ion ion-md-cloudy-night
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-code"></i> ion ion-md-code
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-code-download"></i> ion ion-md-code-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-code-working"></i> ion ion-md-code-working
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cog"></i> ion ion-md-cog
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-color-fill"></i> ion ion-md-color-fill
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-color-filter"></i> ion ion-md-color-filter
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-color-palette"></i> ion ion-md-color-palette
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-color-wand"></i> ion ion-md-color-wand
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-compass"></i> ion ion-md-compass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-construct"></i> ion ion-md-construct
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-contact"></i> ion ion-md-contact
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-contacts"></i> ion ion-md-contacts
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-contract"></i> ion ion-md-contract
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-contrast"></i> ion ion-md-contrast
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-copy"></i> ion ion-md-copy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-create"></i> ion ion-md-create
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-crop"></i> ion ion-md-crop
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cube"></i> ion ion-md-cube
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-cut"></i> ion ion-md-cut
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-desktop"></i> ion ion-md-desktop
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-disc"></i> ion ion-md-disc
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-document"></i> ion ion-md-document
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-done-all"></i> ion ion-md-done-all
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-download"></i> ion ion-md-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-easel"></i> ion ion-md-easel
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-egg"></i> ion ion-md-egg
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-exit"></i> ion ion-md-exit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-expand"></i> ion ion-md-expand
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-eye"></i> ion ion-md-eye
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-eye-off"></i> ion ion-md-eye-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-fastforward"></i> ion ion-md-fastforward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-female"></i> ion ion-md-female
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-filing"></i> ion ion-md-filing
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-film"></i> ion ion-md-film
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-finger-print"></i> ion ion-md-finger-print
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-fitness"></i> ion ion-md-fitness
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-flag"></i> ion ion-md-flag
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-flame"></i> ion ion-md-flame
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-flash"></i> ion ion-md-flash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-flash-off"></i> ion ion-md-flash-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-flashlight"></i> ion ion-md-flashlight
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-flask"></i> ion ion-md-flask
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-flower"></i> ion ion-md-flower
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-folder"></i> ion ion-md-folder
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-folder-open"></i> ion ion-md-folder-open
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-football"></i> ion ion-md-football
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-funnel"></i> ion ion-md-funnel
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-gift"></i> ion ion-md-gift
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-git-branch"></i> ion ion-md-git-branch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-git-commit"></i> ion ion-md-git-commit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-git-compare"></i> ion ion-md-git-compare
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-git-merge"></i> ion ion-md-git-merge
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-git-network"></i> ion ion-md-git-network
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-git-pull-request"></i> ion ion-md-git-pull-request
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-glasses"></i> ion ion-md-glasses
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-globe"></i> ion ion-md-globe
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-grid"></i> ion ion-md-grid
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-hammer"></i> ion ion-md-hammer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-hand"></i> ion ion-md-hand
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-happy"></i> ion ion-md-happy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-headset"></i> ion ion-md-headset
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-heart"></i> ion ion-md-heart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-heart-dislike"></i> ion ion-md-heart-dislike
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-heart-empty"></i> ion ion-md-heart-empty
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-heart-half"></i> ion ion-md-heart-half
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-help"></i> ion ion-md-help
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-help-buoy"></i> ion ion-md-help-buoy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-help-circle"></i> ion ion-md-help-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-help-circle-outline"></i> ion ion-md-help-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-home"></i> ion ion-md-home
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-hourglass"></i> ion ion-md-hourglass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-ice-cream"></i> ion ion-md-ice-cream
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-image"></i> ion ion-md-image
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-images"></i> ion ion-md-images
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-infinite"></i> ion ion-md-infinite
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-information"></i> ion ion-md-information
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-information-circle"></i> ion ion-md-information-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-information-circle-outline"></i> ion ion-md-information-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-jet"></i> ion ion-md-jet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-journal"></i> ion ion-md-journal
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-key"></i> ion ion-md-key
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-keypad"></i> ion ion-md-keypad
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-laptop"></i> ion ion-md-laptop
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-leaf"></i> ion ion-md-leaf
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-link"></i> ion ion-md-link
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-list"></i> ion ion-md-list
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-list-box"></i> ion ion-md-list-box
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-locate"></i> ion ion-md-locate
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-lock"></i> ion ion-md-lock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-log-in"></i> ion ion-md-log-in
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-log-out"></i> ion ion-md-log-out
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-magnet"></i> ion ion-md-magnet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-mail"></i> ion ion-md-mail
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-mail-open"></i> ion ion-md-mail-open
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-mail-unread"></i> ion ion-md-mail-unread
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-male"></i> ion ion-md-male
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-man"></i> ion ion-md-man
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-map"></i> ion ion-md-map
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-medal"></i> ion ion-md-medal
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-medical"></i> ion ion-md-medical
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-medkit"></i> ion ion-md-medkit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-megaphone"></i> ion ion-md-megaphone
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-menu"></i> ion ion-md-menu
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-mic"></i> ion ion-md-mic
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-mic-off"></i> ion ion-md-mic-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-microphone"></i> ion ion-md-microphone
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-moon"></i> ion ion-md-moon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-more"></i> ion ion-md-more
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-move"></i> ion ion-md-move
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-musical-note"></i> ion ion-md-musical-note
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-musical-notes"></i> ion ion-md-musical-notes
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-navigate"></i> ion ion-md-navigate
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-notifications"></i> ion ion-md-notifications
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-notifications-off"></i> ion ion-md-notifications-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-notifications-outline"></i> ion ion-md-notifications-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-nuclear"></i> ion ion-md-nuclear
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-nutrition"></i> ion ion-md-nutrition
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-open"></i> ion ion-md-open
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-options"></i> ion ion-md-options
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-outlet"></i> ion ion-md-outlet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-paper"></i> ion ion-md-paper
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-paper-plane"></i> ion ion-md-paper-plane
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-partly-sunny"></i> ion ion-md-partly-sunny
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-pause"></i> ion ion-md-pause
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-paw"></i> ion ion-md-paw
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-people"></i> ion ion-md-people
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-person"></i> ion ion-md-person
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-person-add"></i> ion ion-md-person-add
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-phone-landscape"></i> ion ion-md-phone-landscape
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-phone-portrait"></i> ion ion-md-phone-portrait
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-photos"></i> ion ion-md-photos
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-pie"></i> ion ion-md-pie
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-pin"></i> ion ion-md-pin
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-pint"></i> ion ion-md-pint
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-pizza"></i> ion ion-md-pizza
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-planet"></i> ion ion-md-planet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-play"></i> ion ion-md-play
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-play-circle"></i> ion ion-md-play-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-podium"></i> ion ion-md-podium
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-power"></i> ion ion-md-power
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-pricetag"></i> ion ion-md-pricetag
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-pricetags"></i> ion ion-md-pricetags
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-print"></i> ion ion-md-print
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-pulse"></i> ion ion-md-pulse
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-qr-scanner"></i> ion ion-md-qr-scanner
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-quote"></i> ion ion-md-quote
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-radio"></i> ion ion-md-radio
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-radio-button-off"></i> ion ion-md-radio-button-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-radio-button-on"></i> ion ion-md-radio-button-on
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-rainy"></i> ion ion-md-rainy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-recording"></i> ion ion-md-recording
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-redo"></i> ion ion-md-redo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-refresh"></i> ion ion-md-refresh
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-refresh-circle"></i> ion ion-md-refresh-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-remove"></i> ion ion-md-remove
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-remove-circle"></i> ion ion-md-remove-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-remove-circle-outline"></i> ion ion-md-remove-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-reorder"></i> ion ion-md-reorder
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-repeat"></i> ion ion-md-repeat
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-resize"></i> ion ion-md-resize
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-restaurant"></i> ion ion-md-restaurant
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-return-left"></i> ion ion-md-return-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-return-right"></i> ion ion-md-return-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-reverse-camera"></i> ion ion-md-reverse-camera
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-rewind"></i> ion ion-md-rewind
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-ribbon"></i> ion ion-md-ribbon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-rocket"></i> ion ion-md-rocket
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-rose"></i> ion ion-md-rose
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-sad"></i> ion ion-md-sad
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-save"></i> ion ion-md-save
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-school"></i> ion ion-md-school
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-search"></i> ion ion-md-search
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-send"></i> ion ion-md-send
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-settings"></i> ion ion-md-settings
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-share"></i> ion ion-md-share
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-share-alt"></i> ion ion-md-share-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-shirt"></i> ion ion-md-shirt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-shuffle"></i> ion ion-md-shuffle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-skip-backward"></i> ion ion-md-skip-backward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-skip-forward"></i> ion ion-md-skip-forward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-snow"></i> ion ion-md-snow
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-speedometer"></i> ion ion-md-speedometer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-square"></i> ion ion-md-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-square-outline"></i> ion ion-md-square-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-star"></i> ion ion-md-star
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-star-half"></i> ion ion-md-star-half
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-star-outline"></i> ion ion-md-star-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-stats"></i> ion ion-md-stats
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-stopwatch"></i> ion ion-md-stopwatch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-subway"></i> ion ion-md-subway
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-sunny"></i> ion ion-md-sunny
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-swap"></i> ion ion-md-swap
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-switch"></i> ion ion-md-switch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-sync"></i> ion ion-md-sync
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-tablet-landscape"></i> ion ion-md-tablet-landscape
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-tablet-portrait"></i> ion ion-md-tablet-portrait
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-tennisball"></i> ion ion-md-tennisball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-text"></i> ion ion-md-text
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-thermometer"></i> ion ion-md-thermometer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-thumbs-down"></i> ion ion-md-thumbs-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-thumbs-up"></i> ion ion-md-thumbs-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-thunderstorm"></i> ion ion-md-thunderstorm
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-time"></i> ion ion-md-time
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-timer"></i> ion ion-md-timer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-today"></i> ion ion-md-today
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-train"></i> ion ion-md-train
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-transgender"></i> ion ion-md-transgender
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-trash"></i> ion ion-md-trash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-trending-down"></i> ion ion-md-trending-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-trending-up"></i> ion ion-md-trending-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-trophy"></i> ion ion-md-trophy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-tv"></i> ion ion-md-tv
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-umbrella"></i> ion ion-md-umbrella
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-undo"></i> ion ion-md-undo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-unlock"></i> ion ion-md-unlock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-videocam"></i> ion ion-md-videocam
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-volume-high"></i> ion ion-md-volume-high
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-volume-low"></i> ion ion-md-volume-low
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-volume-mute"></i> ion ion-md-volume-mute
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-volume-off"></i> ion ion-md-volume-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-walk"></i> ion ion-md-walk
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-wallet"></i> ion ion-md-wallet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-warning"></i> ion ion-md-warning
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-watch"></i> ion ion-md-watch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-water"></i> ion ion-md-water
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-wifi"></i> ion ion-md-wifi
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-wine"></i> ion ion-md-wine
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-md-woman"></i> ion ion-md-woman
              </div>
            </div>
            <!-- End row -->
          </div>
          <!-- end card-box -->

          <div class="card-box">
            <h4 class="header-title mb-4">iOS</h4>

            <div class="row icon-demo-content">
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-add"></i> ion ion-ios-add
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-add-circle"></i> ion ion-ios-add-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-add-circle-outline"></i> ion ion-ios-add-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-airplane"></i> ion ion-ios-airplane
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-alarm"></i> ion ion-ios-alarm
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-albums"></i> ion ion-ios-albums
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-alert"></i> ion ion-ios-alert
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-american-football"></i> ion ion-ios-american-football
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-analytics"></i> ion ion-ios-analytics
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-aperture"></i> ion ion-ios-aperture
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-apps"></i> ion ion-ios-apps
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-appstore"></i> ion ion-ios-appstore
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-archive"></i> ion ion-ios-archive
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-back"></i> ion ion-ios-arrow-back
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-down"></i> ion ion-ios-arrow-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-dropdown"></i> ion ion-ios-arrow-dropdown
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-dropdown-circle"></i> ion ion-ios-arrow-dropdown-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-dropleft"></i> ion ion-ios-arrow-dropleft
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-dropleft-circle"></i> ion ion-ios-arrow-dropleft-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-dropright"></i> ion ion-ios-arrow-dropright
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-dropright-circle"></i> ion ion-ios-arrow-dropright-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-dropup"></i> ion ion-ios-arrow-dropup
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-dropup-circle"></i> ion ion-ios-arrow-dropup-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-forward"></i> ion ion-ios-arrow-forward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-round-back"></i> ion ion-ios-arrow-round-back
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-round-down"></i> ion ion-ios-arrow-round-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-round-forward"></i> ion ion-ios-arrow-round-forward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-round-up"></i> ion ion-ios-arrow-round-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-arrow-up"></i> ion ion-ios-arrow-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-at"></i> ion ion-ios-at
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-attach"></i> ion ion-ios-attach
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-backspace"></i> ion ion-ios-backspace
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-barcode"></i> ion ion-ios-barcode
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-baseball"></i> ion ion-ios-baseball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-basket"></i> ion ion-ios-basket
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-basketball"></i> ion ion-ios-basketball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-battery-charging"></i> ion ion-ios-battery-charging
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-battery-dead"></i> ion ion-ios-battery-dead
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-battery-full"></i> ion ion-ios-battery-full
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-beaker"></i> ion ion-ios-beaker
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-bed"></i> ion ion-ios-bed
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-beer"></i> ion ion-ios-beer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-bicycle"></i> ion ion-ios-bicycle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-bluetooth"></i> ion ion-ios-bluetooth
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-boat"></i> ion ion-ios-boat
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-body"></i> ion ion-ios-body
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-bonfire"></i> ion ion-ios-bonfire
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-book"></i> ion ion-ios-book
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-bookmark"></i> ion ion-ios-bookmark
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-bookmarks"></i> ion ion-ios-bookmarks
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-bowtie"></i> ion ion-ios-bowtie
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-briefcase"></i> ion ion-ios-briefcase
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-browsers"></i> ion ion-ios-browsers
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-brush"></i> ion ion-ios-brush
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-bug"></i> ion ion-ios-bug
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-build"></i> ion ion-ios-build
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-bulb"></i> ion ion-ios-bulb
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-bus"></i> ion ion-ios-bus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-business"></i> ion ion-ios-business
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cafe"></i> ion ion-ios-cafe
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-calculator"></i> ion ion-ios-calculator
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-calendar"></i> ion ion-ios-calendar
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-call"></i> ion ion-ios-call
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-camera"></i> ion ion-ios-camera
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-car"></i> ion ion-ios-car
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-card"></i> ion ion-ios-card
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cart"></i> ion ion-ios-cart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cash"></i> ion ion-ios-cash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cellular"></i> ion ion-ios-cellular
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-chatboxes"></i> ion ion-ios-chatboxes
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-chatbubbles"></i> ion ion-ios-chatbubbles
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-checkbox"></i> ion ion-ios-checkbox
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-checkbox-outline"></i> ion ion-ios-checkbox-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-checkmark"></i> ion ion-ios-checkmark
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-checkmark-circle"></i> ion ion-ios-checkmark-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-checkmark-circle-outline"></i> ion ion-ios-checkmark-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-clipboard"></i> ion ion-ios-clipboard
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-clock"></i> ion ion-ios-clock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-close"></i> ion ion-ios-close
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-close-circle"></i> ion ion-ios-close-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-close-circle-outline"></i> ion ion-ios-close-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cloud"></i> ion ion-ios-cloud
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cloud-circle"></i> ion ion-ios-cloud-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cloud-done"></i> ion ion-ios-cloud-done
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cloud-download"></i> ion ion-ios-cloud-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cloud-outline"></i> ion ion-ios-cloud-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cloud-upload"></i> ion ion-ios-cloud-upload
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cloudy"></i> ion ion-ios-cloudy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cloudy-night"></i> ion ion-ios-cloudy-night
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-code"></i> ion ion-ios-code
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-code-download"></i> ion ion-ios-code-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-code-working"></i> ion ion-ios-code-working
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cog"></i> ion ion-ios-cog
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-color-fill"></i> ion ion-ios-color-fill
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-color-filter"></i> ion ion-ios-color-filter
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-color-palette"></i> ion ion-ios-color-palette
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-color-wand"></i> ion ion-ios-color-wand
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-compass"></i> ion ion-ios-compass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-construct"></i> ion ion-ios-construct
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-contact"></i> ion ion-ios-contact
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-contacts"></i> ion ion-ios-contacts
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-contract"></i> ion ion-ios-contract
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-contrast"></i> ion ion-ios-contrast
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-copy"></i> ion ion-ios-copy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-create"></i> ion ion-ios-create
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-crop"></i> ion ion-ios-crop
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cube"></i> ion ion-ios-cube
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-cut"></i> ion ion-ios-cut
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-desktop"></i> ion ion-ios-desktop
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-disc"></i> ion ion-ios-disc
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-document"></i> ion ion-ios-document
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-done-all"></i> ion ion-ios-done-all
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-download"></i> ion ion-ios-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-easel"></i> ion ion-ios-easel
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-egg"></i> ion ion-ios-egg
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-exit"></i> ion ion-ios-exit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-expand"></i> ion ion-ios-expand
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-eye"></i> ion ion-ios-eye
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-eye-off"></i> ion ion-ios-eye-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-fastforward"></i> ion ion-ios-fastforward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-female"></i> ion ion-ios-female
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-filing"></i> ion ion-ios-filing
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-film"></i> ion ion-ios-film
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-finger-print"></i> ion ion-ios-finger-print
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-fitness"></i> ion ion-ios-fitness
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-flag"></i> ion ion-ios-flag
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-flame"></i> ion ion-ios-flame
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-flash"></i> ion ion-ios-flash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-flash-off"></i> ion ion-ios-flash-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-flashlight"></i> ion ion-ios-flashlight
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-flask"></i> ion ion-ios-flask
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-flower"></i> ion ion-ios-flower
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-folder"></i> ion ion-ios-folder
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-folder-open"></i> ion ion-ios-folder-open
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-football"></i> ion ion-ios-football
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-funnel"></i> ion ion-ios-funnel
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-gift"></i> ion ion-ios-gift
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-git-branch"></i> ion ion-ios-git-branch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-git-commit"></i> ion ion-ios-git-commit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-git-compare"></i> ion ion-ios-git-compare
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-git-merge"></i> ion ion-ios-git-merge
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-git-network"></i> ion ion-ios-git-network
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-git-pull-request"></i> ion ion-ios-git-pull-request
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-glasses"></i> ion ion-ios-glasses
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-globe"></i> ion ion-ios-globe
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-grid"></i> ion ion-ios-grid
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-hammer"></i> ion ion-ios-hammer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-hand"></i> ion ion-ios-hand
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-happy"></i> ion ion-ios-happy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-headset"></i> ion ion-ios-headset
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-heart"></i> ion ion-ios-heart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-heart-dislike"></i> ion ion-ios-heart-dislike
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-heart-empty"></i> ion ion-ios-heart-empty
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-heart-half"></i> ion ion-ios-heart-half
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-help"></i> ion ion-ios-help
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-help-buoy"></i> ion ion-ios-help-buoy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-help-circle"></i> ion ion-ios-help-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-help-circle-outline"></i> ion ion-ios-help-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-home"></i> ion ion-ios-home
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-hourglass"></i> ion ion-ios-hourglass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-ice-cream"></i> ion ion-ios-ice-cream
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-image"></i> ion ion-ios-image
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-images"></i> ion ion-ios-images
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-infinite"></i> ion ion-ios-infinite
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-information"></i> ion ion-ios-information
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-information-circle"></i> ion ion-ios-information-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-information-circle-outline"></i> ion ion-ios-information-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-jet"></i> ion ion-ios-jet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-journal"></i> ion ion-ios-journal
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-key"></i> ion ion-ios-key
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-keypad"></i> ion ion-ios-keypad
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-laptop"></i> ion ion-ios-laptop
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-leaf"></i> ion ion-ios-leaf
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-link"></i> ion ion-ios-link
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-list"></i> ion ion-ios-list
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-list-box"></i> ion ion-ios-list-box
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-locate"></i> ion ion-ios-locate
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-lock"></i> ion ion-ios-lock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-log-in"></i> ion ion-ios-log-in
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-log-out"></i> ion ion-ios-log-out
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-magnet"></i> ion ion-ios-magnet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-mail"></i> ion ion-ios-mail
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-mail-open"></i> ion ion-ios-mail-open
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-mail-unread"></i> ion ion-ios-mail-unread
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-male"></i> ion ion-ios-male
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-man"></i> ion ion-ios-man
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-map"></i> ion ion-ios-map
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-medal"></i> ion ion-ios-medal
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-medical"></i> ion ion-ios-medical
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-medkit"></i> ion ion-ios-medkit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-megaphone"></i> ion ion-ios-megaphone
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-menu"></i> ion ion-ios-menu
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-mic"></i> ion ion-ios-mic
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-mic-off"></i> ion ion-ios-mic-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-microphone"></i> ion ion-ios-microphone
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-moon"></i> ion ion-ios-moon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-more"></i> ion ion-ios-more
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-move"></i> ion ion-ios-move
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-musical-note"></i> ion ion-ios-musical-note
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-musical-notes"></i> ion ion-ios-musical-notes
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-navigate"></i> ion ion-ios-navigate
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-notifications"></i> ion ion-ios-notifications
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-notifications-off"></i> ion ion-ios-notifications-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-notifications-outline"></i> ion ion-ios-notifications-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-nuclear"></i> ion ion-ios-nuclear
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-nutrition"></i> ion ion-ios-nutrition
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-open"></i> ion ion-ios-open
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-options"></i> ion ion-ios-options
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-outlet"></i> ion ion-ios-outlet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-paper"></i> ion ion-ios-paper
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-paper-plane"></i> ion ion-ios-paper-plane
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-partly-sunny"></i> ion ion-ios-partly-sunny
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-pause"></i> ion ion-ios-pause
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-paw"></i> ion ion-ios-paw
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-people"></i> ion ion-ios-people
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-person"></i> ion ion-ios-person
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-person-add"></i> ion ion-ios-person-add
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-phone-landscape"></i> ion ion-ios-phone-landscape
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-phone-portrait"></i> ion ion-ios-phone-portrait
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-photos"></i> ion ion-ios-photos
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-pie"></i> ion ion-ios-pie
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-pin"></i> ion ion-ios-pin
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-pint"></i> ion ion-ios-pint
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-pizza"></i> ion ion-ios-pizza
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-planet"></i> ion ion-ios-planet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-play"></i> ion ion-ios-play
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-play-circle"></i> ion ion-ios-play-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-podium"></i> ion ion-ios-podium
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-power"></i> ion ion-ios-power
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-pricetag"></i> ion ion-ios-pricetag
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-pricetags"></i> ion ion-ios-pricetags
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-print"></i> ion ion-ios-print
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-pulse"></i> ion ion-ios-pulse
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-qr-scanner"></i> ion ion-ios-qr-scanner
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-quote"></i> ion ion-ios-quote
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-radio"></i> ion ion-ios-radio
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-radio-button-off"></i> ion ion-ios-radio-button-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-radio-button-on"></i> ion ion-ios-radio-button-on
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-rainy"></i> ion ion-ios-rainy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-recording"></i> ion ion-ios-recording
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-redo"></i> ion ion-ios-redo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-refresh"></i> ion ion-ios-refresh
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-refresh-circle"></i> ion ion-ios-refresh-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-remove"></i> ion ion-ios-remove
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-remove-circle"></i> ion ion-ios-remove-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-remove-circle-outline"></i> ion ion-ios-remove-circle-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-reorder"></i> ion ion-ios-reorder
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-repeat"></i> ion ion-ios-repeat
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-resize"></i> ion ion-ios-resize
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-restaurant"></i> ion ion-ios-restaurant
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-return-left"></i> ion ion-ios-return-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-return-right"></i> ion ion-ios-return-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-reverse-camera"></i> ion ion-ios-reverse-camera
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-rewind"></i> ion ion-ios-rewind
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-ribbon"></i> ion ion-ios-ribbon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-rocket"></i> ion ion-ios-rocket
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-rose"></i> ion ion-ios-rose
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-sad"></i> ion ion-ios-sad
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-save"></i> ion ion-ios-save
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-school"></i> ion ion-ios-school
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-search"></i> ion ion-ios-search
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-send"></i> ion ion-ios-send
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-settings"></i> ion ion-ios-settings
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-share"></i> ion ion-ios-share
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-share-alt"></i> ion ion-ios-share-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-shirt"></i> ion ion-ios-shirt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-shuffle"></i> ion ion-ios-shuffle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-skip-backward"></i> ion ion-ios-skip-backward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-skip-forward"></i> ion ion-ios-skip-forward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-snow"></i> ion ion-ios-snow
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-speedometer"></i> ion ion-ios-speedometer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-square"></i> ion ion-ios-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-square-outline"></i> ion ion-ios-square-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-star"></i> ion ion-ios-star
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-star-half"></i> ion ion-ios-star-half
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-star-outline"></i> ion ion-ios-star-outline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-stats"></i> ion ion-ios-stats
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-stopwatch"></i> ion ion-ios-stopwatch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-subway"></i> ion ion-ios-subway
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-sunny"></i> ion ion-ios-sunny
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-swap"></i> ion ion-ios-swap
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-switch"></i> ion ion-ios-switch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-sync"></i> ion ion-ios-sync
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-tablet-landscape"></i> ion ion-ios-tablet-landscape
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-tablet-portrait"></i> ion ion-ios-tablet-portrait
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-tennisball"></i> ion ion-ios-tennisball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-text"></i> ion ion-ios-text
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-thermometer"></i> ion ion-ios-thermometer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-thumbs-down"></i> ion ion-ios-thumbs-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-thumbs-up"></i> ion ion-ios-thumbs-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-thunderstorm"></i> ion ion-ios-thunderstorm
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-time"></i> ion ion-ios-time
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-timer"></i> ion ion-ios-timer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-today"></i> ion ion-ios-today
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-train"></i> ion ion-ios-train
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-transgender"></i> ion ion-ios-transgender
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-trash"></i> ion ion-ios-trash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-trending-down"></i> ion ion-ios-trending-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-trending-up"></i> ion ion-ios-trending-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-trophy"></i> ion ion-ios-trophy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-tv"></i> ion ion-ios-tv
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-umbrella"></i> ion ion-ios-umbrella
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-undo"></i> ion ion-ios-undo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-unlock"></i> ion ion-ios-unlock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-videocam"></i> ion ion-ios-videocam
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-volume-high"></i> ion ion-ios-volume-high
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-volume-low"></i> ion ion-ios-volume-low
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-volume-mute"></i> ion ion-ios-volume-mute
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-volume-off"></i> ion ion-ios-volume-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-walk"></i> ion ion-ios-walk
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-wallet"></i> ion ion-ios-wallet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-warning"></i> ion ion-ios-warning
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-watch"></i> ion ion-ios-watch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-water"></i> ion ion-ios-water
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-wifi"></i> ion ion-ios-wifi
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-wine"></i> ion ion-ios-wine
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-ios-woman"></i> ion ion-ios-woman
              </div>
            </div>
          </div>
          <!-- end card-box -->

          <div class="card-body">
            <h4 class="card-title mb-4">Logo</h4>

            <div class="row icon-demo-content">
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-android"></i> ion ion-logo-android
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-angular"></i> ion ion-logo-angular
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-apple"></i> ion ion-logo-apple
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-bitbucket"></i> ion ion-logo-bitbucket
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-bitcoin"></i> ion ion-logo-bitcoin
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-buffer"></i> ion ion-logo-buffer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-chrome"></i> ion ion-logo-chrome
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-closed-captioning"></i> ion ion-logo-closed-captioning
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-codepen"></i> ion ion-logo-codepen
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-css3"></i> ion ion-logo-css3
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-designernews"></i> ion ion-logo-designernews
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-dribbble"></i> ion ion-logo-dribbble
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-dropbox"></i> ion ion-logo-dropbox
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-euro"></i> ion ion-logo-euro
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-facebook"></i> ion ion-logo-facebook
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-flickr"></i> ion ion-logo-flickr
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-foursquare"></i> ion ion-logo-foursquare
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-freebsd-devil"></i> ion ion-logo-freebsd-devil
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-game-controller-a"></i> ion ion-logo-game-controller-a
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-game-controller-b"></i> ion ion-logo-game-controller-b
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-github"></i> ion ion-logo-github
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-google"></i> ion ion-logo-google
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-googleplus"></i> ion ion-logo-googleplus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-hackernews"></i> ion ion-logo-hackernews
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-html5"></i> ion ion-logo-html5
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-instagram"></i> ion ion-logo-instagram
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-ionic"></i> ion ion-logo-ionic
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-ionitron"></i> ion ion-logo-ionitron
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-javascript"></i> ion ion-logo-javascript
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-linkedin"></i> ion ion-logo-linkedin
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-markdown"></i> ion ion-logo-markdown
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-model-s"></i> ion ion-logo-model-s
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-no-smoking"></i> ion ion-logo-no-smoking
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-nodejs"></i> ion ion-logo-nodejs
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-npm"></i> ion ion-logo-npm
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-octocat"></i> ion ion-logo-octocat
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-pinterest"></i> ion ion-logo-pinterest
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-playstation"></i> ion ion-logo-playstation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-polymer"></i> ion ion-logo-polymer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-python"></i> ion ion-logo-python
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-reddit"></i> ion ion-logo-reddit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-rss"></i> ion ion-logo-rss
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-sass"></i> ion ion-logo-sass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-skype"></i> ion ion-logo-skype
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-slack"></i> ion ion-logo-slack
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-snapchat"></i> ion ion-logo-snapchat
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-steam"></i> ion ion-logo-steam
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-tumblr"></i> ion ion-logo-tumblr
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-tux"></i> ion ion-logo-tux
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-twitch"></i> ion ion-logo-twitch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-twitter"></i> ion ion-logo-twitter
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-usd"></i> ion ion-logo-usd
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-vimeo"></i> ion ion-logo-vimeo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-vk"></i> ion ion-logo-vk
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-whatsapp"></i> ion ion-logo-whatsapp
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-windows"></i> ion ion-logo-windows
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-wordpress"></i> ion ion-logo-wordpress
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-xbox"></i> ion ion-logo-xbox
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-xing"></i> ion ion-logo-xing
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-yahoo"></i> ion ion-logo-yahoo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-yen"></i> ion ion-logo-yen
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="ion ion-logo-youtube"></i> ion ion-logo-youtube
              </div>
            </div>
          </div>
        </div>
        <!-- end card-box -->
      </div>
      <!-- col-->
    </div>
    <!-- End row -->
  </Layout>
</template>